<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiMatrix }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-card class="mx-2" elevation="1">
          <v-card-text>
            <v-list dense>
              <v-subheader class="text-h6">Matrices</v-subheader>
              <v-list-item-group>
                <v-list-item v-for="(labor, index) in labors" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ labor.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-show="labor.default">
                    <v-icon>{{ icons.mdiStar }}</v-icon>
                  </v-list-item-icon>
                  <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn light icon v-bind="attrs" v-on="on">
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click.prevent="editLaborModal(labor)">
                        <v-list-item-icon>
                          <v-icon color="primary" class="mx-1">{{ icons.mdiPencil }}</v-icon>
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item @click.prevent="">
                        <v-list-item-icon>
                          <v-icon color="error" class="mx-1">{{ icons.mdiClose }}</v-icon>
                          <v-list-item-title>Remove</v-list-item-title>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-layout class="ma-2">
              <div v-for="(permission, index) in permission" :key="index">
                <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                  <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                    <v-btn
                      v-show="user_submenu.create == 1 && permission.role_id == 3"
                      color="accent"
                      rounded
                      @click.prevent="addLaborModal"
                    >
                      <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                      <span>Create New </span>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div>
                <v-btn v-show="currentUser.role_id == 1" color="accent" rounded @click.prevent="addLaborModal">
                  <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                  <span>Create New </span>
                </v-btn>
              </div>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-card elevation="1" v-for="(laborItem, index1) in laborItems" :key="index1">
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="2" class="text-left">Hour</th>
                    <th class="text-left">Multiplier</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index2) in laborItem.labor_items" :key="index2">
                    <td width="150px">
                      <input type="hidden" readonly v-model="laborItem.labor_items[index2].labor_id" />
                      <v-text-field
                        v-model="laborItem.labor_items[index2].hour_range1"
                        color="secondary"
                        label="Hour"
                        dense
                        outlined
                      ></v-text-field>
                    </td>

                    <td width="150px">
                      <v-text-field
                        v-model="laborItem.labor_items[index2].hour_range2"
                        color="secondary"
                        label="Hour"
                        dense
                        outlined
                      ></v-text-field>
                    </td>
                    <td width="150px">
                      <v-text-field
                        :append-icon="icons.mdiClose"
                        v-model="laborItem.labor_items[index2].multiplier"
                        color="secondary"
                        label="Multiplier"
                        dense
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div d-flex justify-space-between>
              <v-layout>
                <div v-for="(permission, index) in permission" :key="index">
                  <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                    <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                      <v-btn
                        class="ma-2"
                        v-show="user_submenu.create == 1 && permission.role_id == 3"
                        @click.prevent="addHour(index1)"
                        outlined
                        color="primary"
                        ><v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon
                        >Add Range
                      </v-btn>
                      <v-btn
                        @click.prevent="saveHour"
                        v-show="user_submenu.create == 1 && permission.role_id == 3"
                        class="ma-2"
                        outlined
                        color="primary"
                        ><v-icon class="mr-1">{{ icons.mdiContentSave }}</v-icon
                        >Save
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div>
                  <v-btn
                    class="ma-2"
                    v-show="currentUser.role_id == 1"
                    @click.prevent="addHour(index1)"
                    outlined
                    color="primary"
                    ><v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon
                    >Add Range
                  </v-btn>
                  <v-btn
                    @click.prevent="saveHour"
                    v-show="currentUser.role_id == 1"
                    class="ma-2"
                    outlined
                    color="primary"
                    ><v-icon class="mr-1">{{ icons.mdiContentSave }}</v-icon
                    >Save
                  </v-btn>
                </div>
              </v-layout>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <addnew-labor :dialog.sync="showNewLabor" @open-dialog="showNewLabor = true" @close-dialog="showNewLabor = false" />
    <edit-labor
      :dialog.sync="showEditLabor"
      :labor="labor"
      @open-dialog="showEditLabor = true"
      @close-dialog="showEditLabor = false"
    />
  </v-card>
</template>
  
  <script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import {
  mdiAccountTie,
  mdiPlus,
  mdiLayersTripleOutline,
  mdiCar,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiMessageBulleted,
  mdiCheckboxMarkedOutline,
  mdiClipboardCheckOutline,
  mdiMatrix,
  mdiDotsVertical,
  mdiPencil,
  mdiStar,
  mdiPercentOutline,
} from '@mdi/js'

import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { proxyRefs } from '@vue/composition-api'
import AddNewDialog from '@/views/settings/labor/AddLabor.vue'
import EditDialog from '@/views/settings/labor/EditLabor.vue'
export default {
  components: {
    'addnew-labor': AddNewDialog,
    'edit-labor': EditDialog,
  },
  created: function () {
    this.getLabors()
    this.getAuthorizations()
    this.getLaborById(1)
    window.mitt.on('loadLabor', () => {
      this.getLabors()
      this.getLaborById(1)
      this.getAuthorizations()
    })
  },
  data() {
    return {
      labors: [],
      laborItems: [
        {
          name: '',
          default: '',
          type: '',
          labor_items: [
            {
              labor_item_id: '',
              labor_id: '',
              hour_range1: '',
              hour_range2: '',
              multiplier: '',
            },
          ],
        },
      ],
      labor: {},
      permission: {},
      showNewLabor: false,
      showEditLabor: false,
      icons: {
        mdiAccountTie,
        mdiPencil,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiLayersTripleOutline,
        mdiCar,
        mdiMessageBulleted,
        mdiCheckboxMarkedOutline,
        mdiClipboardCheckOutline,
        mdiMatrix,
        mdiDotsVertical,
        mdiStar,
        mdiPercentOutline,
      },
    }
  },
  methods: {
    getLabors: function () {
      this.$store
        .dispatch('labor/fetchLabors')
        .then(response => {
          this.labors = this.fetchLabors
        })
        .catch(err => {
          console.log(err)
        })
    },
    getLaborById: function (id) {
      this.$store
        .dispatch('labor/fetchLaborById', {
          labor_id: id,
        })
        .then(response => {
          this.laborItems = this.fetchLaborItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 9,
          submenu_id: 13,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    addHour: function (index1) {
      this.laborItems[index1].labor_items.push({
        labor_item_id: '',
        labor_id: '',
        hour_range1: '',
        hour_range2: '',
        multiplier: '',
      })
    },
    saveHour: function () {
      this.$store
        .dispatch('labor/storeNewLaborItem', {
          laborItems: this.laborItems,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })
          this.getLaborById(1)
        })
        .catch(err => {
          console.log(err)
        })
    },

    addLaborModal: function () {
      this.showNewLabor = true
    },
    editLaborModal: function (labor) {
      this.labor = Object.assign({}, labor)
      this.showEditLabor = true
    },
  },

  computed: {
    ...mapGetters({
      fetchLabors: 'labor/fetchLabors',
      fetchLaborItems: 'labor/fetchLaborItems',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),

    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>
  <style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 8px 14px;
}
</style>